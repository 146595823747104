import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/components/Login.vue";
import Register from "@/components/Register.vue";
// lazy-loaded
const Profile = () => import("@/components/Profile.vue")
const BoardAdmin = () => import("@/components/BoardAdmin.vue")
const BoardModerator = () => import("@/components/BoardModerator.vue")
const BoardUser = () => import("@/components/BoardUser.vue")

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },
  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdmin,
  },
  {
    path: "/mod",
    name: "moderator",
    // lazy-loaded
    component: BoardModerator,
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUser,
  },
  {
    path: "/enfants",
    name: "enfants",
    component: () => import("./components/EnfantsList")
  },
  {
    path: "/enfants/:id",
    name: "enfant-details",
    component: () => import("./components/Enfant")
  },
  {
    path: "/enfant/add",
    name: "addEnfant",
    component: () => import("./components/AddEnfant")
  },
  {
    path: "/mariages",
    name: "mariages",
    component: () => import("./components/MariagesList")
  },
  {
    path: "/mariages/:id",
    name: "mariage-details",
    component: () => import("./components/Mariage")
  },
  {
    path: "/mariage/add",
    name: "addMariage",
    component: () => import("./components/AddMariage")
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/register', '/home'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
// });

export default router;